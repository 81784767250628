import service from "../utils/request";

export function login(data){
    return service({
        url: "/account/login",
        method:"post",
        data
    });
}

export function changePassword(data){
    return service({
        url:"/account/resetPassword",
        method:"post",
        data
    })
}


export function getVerifyCode(phone){
    return service({
        url:"/account/GetSmsVerifyCode",
        method:"get",
        params:{
            phone
        }
    })
}
