import axios from "axios";
import { getToken } from "./auth";
import store from "@/store";
// create an axios instance
const service = axios.create({
    baseURL: "/api", // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 5000, // request timeout
});


service.interceptors.request.use(
    function (config) {
        // 在发送请求之前做些什么

                if (store.state.token) {
                    // let each request carry token
                    // ['X-Token'] is a custom headers key
                    // please modify it according to the actual situation
                    config.headers["Authorization"] = getToken();
                }
        return config;
    },
    function (error) {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

// 添加响应拦截器
service.interceptors.response.use(
    function (response) {
        // 对响应数据做点什么
        return response;
    },
    function (error) {
        // 对响应错误做点什么
        return Promise.reject(error);
    }
);


export default service;
