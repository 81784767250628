import { createRouter, createWebHistory } from 'vue-router'
import Layout from "@/layout/index.vue";


const routes = [
    {
        path: "/",
        redirect: "/home",
        component: Layout,
        children: [
            {
                path: "/home",
                component: () => import("@/views/home/index.vue"),
            },
        ],
    },
    {
        path: "/404",
        component: () => import("@/views/error-page/404"),
        hidden: true,
    },
    {
        path: "/Account/Login",
        redirect: "/home",
        component: Layout,
        children: [
            {
                path: "/home",
                component: () => import("@/views/home/index.vue"),
            },
        ],
    },
    {
        path: "/educate",
        component: Layout,
        children: [
            {
                path: "/educate",
                component: () => import("@/views/educate/index.vue"),
            },
        ],
    },
    {
        path: "/test",
        component: Layout,
        children: [
            {
                path: "/test",
                component: () => import("@/views/test/index.vue"),
            },
        ],
    },
    {
        path: "/exercise",
        component: Layout,
        children: [
            {
                path: "/exercise",
                component: () => import("@/views/exercise/index.vue"),
            },
        ],
    },
    {
        path: "/guide",
        component: Layout,
        children: [
            {
                path: "/guide",
                component: () => import("@/views/guide/index.vue"),
            },
        ],
    },
    {
        path: "/article",
        component: Layout,
        children: [
            {
                path: "/article",
                component: () => import("@/views/article/index.vue"),
            },
        ],
    },
    {
        path: "/about",
        component: Layout,
        children: [
            {
                path: "/about",
                component: () => import("@/views/about/index.vue"),
            },
        ],
    },
    {
        path: "/profile",
        component: Layout,
        children: [
            {
                path: "/profile",
                redirect: "/profile/info",
                component: () => import("@/views/profile/index.vue"),
                children: [
                    {
                        path: "info",
                        component: () => import("@/views/profile/components/MyInfo.vue"),
                    },

                    {
                        path: "order",
                        component: () => import("@/views/profile/components/MyOrder.vue"),
                    },
                    {
                        path: "resetPsw",
                        component: () => import("@/views/profile/components/ResetPsw.vue"),
                    },
                    {
                        path: "capturePic",
                        component: () => import("@/views/profile/components/CapturePic.vue"),
                    },
                ],
            },
        ],
    },
    {
        path: "/course",
        component: Layout,
        children: [
            {
                path: "/course",
                component: () => import("@/views/course/index.vue"),
            },
        ],
    },
    {
        path: "/study",
        component: Layout,
        children: [
            {
                name: "study",
                path: "/study",
                component: () => import("@/views/study/index.vue"),
            },
        ],
    },
    {
        path: "/elective",
        component: Layout,
        children: [
            {
                path: "/elective",
                component: () => import("@/views/elective/index.vue"),
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // 始终滚动到顶部
        return { top: 0 };
    },
});


export default router
