import { createStore } from "vuex";
import { getToken, removeToken } from "@/utils/auth";
import { getProfile } from "@/api/profile";

export default createStore({
    state: {
        token: getToken(),
        isNewUser: false,
        userInfo: {
            FaceImage: "",
            UserCode: "",
            UserName: "",
            Sex: false,
            Organization: "",
            Mobile: "",
            ContactTel: "",
        },
        videoList: [],
        isVerify: false,
    },
    getters: {},
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token;
        },
        SET_VideoList(state, list) {
            state.videoList = list;
        },
        SET_Verify(state, flag) {
            state.isVerify = flag;
        },
        SET_NewUser(state, flag) {
            state.isNewUser = flag;
        },

        SET_USER_INFO(state, userInfo) {
            state.userInfo = userInfo;
        },
    },
    actions: {
        setVideoList({ commit },list){
            return new Promise(resolve => {
                commit('SET_VideoList', list)
                resolve(list)
            })
        },
        getUserInfo({ commit }) {
            return new Promise((resolve, reject) => {
                getProfile()
                    .then(res => {
                        if (res.data.IsSuccess) {
                            commit("SET_USER_INFO",res.data.Data);
                            if (res.data.Data.Mobile) {
                                commit("SET_NewUser", false);
                            } else {
                                commit("SET_NewUser", true);
                            }
                            resolve(true);
                        }
                        if (!res.data.IsSuccess) {
                            reject("登录已过期,请重新登录!");
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        logout({ commit }) {
            return new Promise((resolve, reject) => {
                removeToken(); // must remove  token  first
                commit("SET_TOKEN", "");
                commit("SET_NewUser", false);
                resolve();
            });
        },
    },
    modules: {},
});
