import "core-js/modules/es.array.push.js";
import Login from "@/components/Login.vue";
import { mapState } from "vuex";
import store from "@/store";
export default {
  components: {
    Login
  },
  data() {
    return {
      showLogin: false
    };
  },
  computed: {
    ...mapState(["token"])
  },
  methods: {
    login() {
      this.showLogin = true;
    },
    logout() {
      store.dispatch("logout");
      this.$router.replace("/");
    },
    toProfile() {
      this.$router.push("/profile");
    }
  }
};