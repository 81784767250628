import { createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import NavBar from "./components/NavBar/index.vue";
import Foot from "./components/Foot/index.vue";
import AppMain from "./components/AppMain.vue";
export default {
  __name: 'index',
  setup(__props) {
    // import Head from "./components/Head/index.vue";

    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(NavBar), _createVNode(AppMain), _createVNode(Foot)], 64);
    };
  }
};