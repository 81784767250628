import "core-js/modules/es.array.push.js";
import { login } from "@/api/account.js";
import { setToken } from "@/utils/auth.js";
import store from "@/store";
export default {
  data() {
    return {
      form: {
        userType: 0
      },
      isSavePsw: false,
      errorMsg: ""
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    submit() {
      login(this.form).then(res => {
        if (res.data.IsSuccess) {
          const Data = res.data.Data;
          const date = new Date(Data.Expiration);
          if (this.isSavePsw == true) {
            //保存密码
            store.commit("SET_TOKEN", Data.Ticket);
            setToken(Data.Ticket, date);
            store.dispatch("getUserInfo");
          } else {
            //不保存
            store.commit("SET_TOKEN", Data.Ticket);
            setToken(Data.Ticket);
            store.dispatch("getUserInfo");
          }
          this.$emit("close");
          this.$router.push("/course");
        } else {
          {
            this.errorMsg = res.data.Message;
          }
        }
      });
    }
  }
};