import service from "@/utils/request";
export function getProfile(){
    return service({
        url: "/userCenter/currentDetail",
        method:"get"
    });
}

export function updateProfile(data) {
    return service({
        url: "userCenter/Modify",
        method: "post",
        data,
    });
}

export function upLoadVerifyPic(data){
    return service({
        url:'/userCenter/UploadFaceImage',
        method:"post",
        data
    })
}


export function getPayment(params){
    return service({
        url:"/userCenter/payment",
        method:"get",
        params
    })
}


//获取证书
export function getCertificate(){
    return service({
        url: "/userCenter/Certificate",
        method:"get"
    });
}


